import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={1}
        lesson={"Lesson 3"}
        color={"ms"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 1</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>Work in a team of 2-3 students for this step.</p>
              <p>Think of an advertisement or website you have seen. Think about what that ad or site was trying to get you to do. Maybe they were trying to get you to buy something or to convince you of an idea.</p>
              <p>Can you identify ways that the writers were trying to influence you? What kinds of things do they emphasize in ads? Discuss your ideas with your teammates and write your ideas in your science notebook.</p>
              <p>You will come back to this list later in the lesson.</p>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Ask students to work in teams of 2-3. In this step, teams think of an example of a website or advertisement that was trying to persuade the reader to buy or believe something. Teams brainstorm ways that the writers were trying to influence them. Encourage students to write ideas in their science notebook even if they are not sure about them. In general terms, students may think of things like high quality, good price, good cause, better than other similar products, or need for the product.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
